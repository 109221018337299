import React from 'react'
import ReactDOM from 'react-dom/client'
import {RouterProvider} from 'react-router-dom'
import {RecoilRoot} from 'recoil'
import {ErrorBoundary} from './components/ErrorBoundary'
import QueryProvider from './components/providers/QueryProvider'
import AuthProvider from './contexts/AuthContext'
import reportWebVitals from './reportWebVitals'
import Router from './router/Router'
import GlobalStyle from './styles/GlobalStyle'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <ErrorBoundary>
    <RecoilRoot>
      <QueryProvider>
        <React.StrictMode>
          <GlobalStyle />
          <AuthProvider>
            <RouterProvider router={Router()} />
          </AuthProvider>
        </React.StrictMode>
      </QueryProvider>
    </RecoilRoot>
    ,
  </ErrorBoundary>,
)

reportWebVitals()
