import SubTab from 'src/components/tab/SubTab'
import theme from 'src/styles/theme'

interface TabProps {
  data: any[]
  selected: any
  handleClick: any
}

function Tab({data = [], selected = '', handleClick = () => void {}}: TabProps) {
  return (
    <>
      {data.map((item, index) => {
        const isSelected = selected === item.projectId
        return (
          <div key={index}>
            <SubTab
              title={item.processId}
              index={index}
              style={{
                color: isSelected ? '#0969DA' : '#b0b0b0',
                border: isSelected ? `1px solid ${theme.color.gray700}` : 'none',
                borderWidth: isSelected ? '1px 1px 0px 1px' : 'none',
                borderRadius: '10px 10px 0px 0px',
                borderColor: isSelected
                  ? `${theme.color.gray700} ${theme.color.gray700} ${theme.color.red} ${theme.color.gray700}`
                  : '',
                outline: isSelected ? `1px solid ${theme.color.white}` : 'none',
                fontWeight: `${theme.fontWeight.bold}`,
                backgroundColor: isSelected ? '#ffffff' : undefined,
              }}
              onClick={() => handleClick({index})}
              // onClick={() => handleClick({index})}
              // onClick={() => handleClick({id: step})} disabled={step > activeStep}>
            />
          </div>
        )
      })}
    </>
  )
}

export default Tab
