import MainCardLayout from 'src/components/mainCard/Layout'
import theme from 'src/styles/theme'
import styled from 'styled-components'

//* 보안 담당자 전용 컴포넌트
function SecurityCard() {
  return (
    <MainCardLayout title="Security">
      <StyledWrapper>
        <StyledItemWrapper>
          <StyledTitleWrapper>
            <StyledTitle>리스크 체크</StyledTitle>
            <StyledSubTitle>양식 및 작성법을 확인합니다.</StyledSubTitle>
          </StyledTitleWrapper>

          <StyledGoWrapper>Go</StyledGoWrapper>
        </StyledItemWrapper>
        <StyledItemWrapper>
          <StyledTitleWrapper>
            <StyledTitle>모의해킹</StyledTitle>
            <StyledSubTitle>위험 모델링 분석 자료입니다.</StyledSubTitle>
          </StyledTitleWrapper>

          <StyledGoWrapper>Go</StyledGoWrapper>
        </StyledItemWrapper>

        <StyledItemWrapper>
          <StyledTitleWrapper>
            <StyledTitle>코드 분석</StyledTitle>
            <StyledSubTitle>3단계 코드 검토 가이드입니다.</StyledSubTitle>
          </StyledTitleWrapper>

          <StyledGoWrapper>Go</StyledGoWrapper>
        </StyledItemWrapper>
      </StyledWrapper>
    </MainCardLayout>
  )
}

export default SecurityCard

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`

const StyledTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const StyledTitle = styled.span`
  font-weight: ${theme.fontWeight.bold};
  font-size: 1.3rem;
`

const StyledSubTitle = styled.span`
  font-weight: ${theme.fontWeight.light};
  font-size: 0.9rem;
  color: ${theme.color.gray700};
`

const StyledGoWrapper = styled.div`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100px;
  height: 40px;
  border-radius: 5px;
  background-color: ${theme.color.gray100};
  color: ${theme.color.gray700};
  font-weight: ${theme.fontWeight.bold};
  cursor: pointer;
  transition:
    background-color 0.15s ease,
    color 0.1s ease;

  &:hover {
    background-color: #5358fd;
    color: white;
  }
`
