import {ProjectHistoryType, ProjectListType} from 'src/types/project'
import {instanceWithToken} from '.'

// 프로젝트 조회
export async function handleProjectList(user_id?: number) {
  const response = await instanceWithToken.get(
    user_id ? `/prj/prjList?user_id=${user_id}` : `/prj/prjList`,
  )
  return response.data
}

// 프로젝트 버전 상세
export async function handleProjectDetail(data: ProjectListType) {
  const response = await instanceWithToken.get(
    `/prj/detail?prj_id=${data.prj_id}&version_number=${data.version_number}`,
  )
  return response.data
}

// 특정 프로젝트 히스토리
export async function handleProjectHistoryList(data: ProjectHistoryType) {
  const response = await instanceWithToken.get('/prj/prjHst', {params: data})
  return response.data
}

// 특정 프로젝트 최신 버전 조회
export async function handleProjectLatestVersion(prj_id: number) {
  const response = await instanceWithToken.get(`/prj/lstVer?prj_id=${prj_id}`)
  return response.data
}
