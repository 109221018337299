import Lottie from 'lottie-react'

interface ILottieWrapper {
  lottieData: object
}

function LottieWrapper({lottieData}: ILottieWrapper) {
  return <Lottie animationData={lottieData} loop={false} autoplay={true} />
}

export default LottieWrapper
