import {useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {handleForgotPassword} from 'src/apis/sign'
import checkImg from 'src/assets/icons/check.png'
import CommonLink from 'src/components/CommonLink'
import LabelInput from 'src/components/form/labelInput'
import AuthLayout from 'src/components/layout/AuthLayout'
import theme from 'src/styles/theme'
import {CommonFormValuesType, SignFormValuesType} from 'src/types/user'
import {styled} from 'styled-components'

//* 비밀번호 찾기 page
function ForgotPassword() {
  const methods = useForm<SignFormValuesType>({mode: 'onChange'})
  const [message, setMessage] = useState('')

  const onSubmit = async (data: CommonFormValuesType) => {
    setMessage('잠시만 기다려주세요!')
    try {
      const response = await handleForgotPassword(data)

      if (response?.resultCode == 400) {
        setMessage('')
        methods.setError(
          'login_id',
          {message: '가입되어 있지 않은 이메일입니다.'},
          {shouldFocus: true},
        )
      }

      if (response?.resultCode == 200) {
        setMessage('해당 이메일로 현재 비밀번호가 전송되었습니다.')
      }
      return true
    } catch (error) {
      return false
    }
  }

  return (
    <FormProvider {...methods}>
      <AuthLayout
        pageTitle="비밀번호 찾기"
        buttonTitle="비밀번호 찾기"
        handleSubmit={onSubmit}
        methods={methods}
        bottomContent={
          <>
            <CommonLink to="/changePassword" title="비밀번호 변경" />
            <CommonLink to="/" title="Sign In" />
          </>
        }>
        <LabelInput type="login_id" label="Email" width="360px" />

        {message && (
          <StyledEmailSendMsg>
            {message == '해당 이메일로 현재 비밀번호가 전송되었습니다.' && (
              <img src={checkImg} alt="check icon" width={16} />
            )}

            <span>{message}</span>
          </StyledEmailSendMsg>
        )}
      </AuthLayout>
    </FormProvider>
  )
}

export default ForgotPassword

const StyledEmailSendMsg = styled.span`
  display: flex;
  align-items: center;
  gap: 3px;
  color: ${theme.color.blue700};
  font-weight: ${theme.fontWeight.bold};
  font-size: 0.9rem;
`
