//* 버전 비교 함수
function compareVersions(version: string, latestVersion: string): number {
  const versionParts = version.split('.').map(Number)
  const latestVersionParts = latestVersion.split('.').map(Number)

  const maxLength = Math.max(versionParts.length, latestVersionParts.length)

  for (let i = 0; i < maxLength; i++) {
    const version = versionParts[i] || 0
    const latestVersion = latestVersionParts[i] || 0

    if (version > latestVersion) return 1
    if (version < latestVersion) return -1
  }

  return 0
}

export default compareVersions
