import {useQuery} from '@tanstack/react-query'
import {useState} from 'react'
import {Link} from 'react-router-dom'
import {useRecoilValue} from 'recoil'
import {handleProjectList} from 'src/apis/project'
import Loading from 'src/components/Loading'
import MainCardLayout from 'src/components/mainCard/Layout'
import Pagination from 'src/components/Pagination'
import QUERY_KEYS from 'src/constants/queryKey'
import {authStateAtom} from 'src/stores/user'
import theme from 'src/styles/theme'
import {ProjectType} from 'src/types/project'
import styled from 'styled-components'

function InProgress() {
  const getAuthStateAtom = useRecoilValue(authStateAtom)

  const {data, error, isLoading} = useQuery({
    queryKey: [QUERY_KEYS.prjListStatus],
    queryFn: () =>
      getAuthStateAtom.userLevel === 1
        ? handleProjectList()
        : handleProjectList(getAuthStateAtom.userId),
  })

  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 5

  if (error) {
    return <div>데이터를 불러오는데 실패했습니다.</div>
  }

  if (isLoading) {
    return <Loading />
  }

  const projects = Array.isArray(data?.data) ? data.data : []

  const inProgressProjects = projects.filter(
    (project: ProjectType) => project.step_status === '검토중',
  )

  const totalPages = Math.ceil(inProgressProjects.length / itemsPerPage)
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const paginatedProjects = inProgressProjects.slice(startIndex, endIndex)
  return (
    <MainCardLayout title="In Progress" subTitle="진행중인 프로젝트로 이동합니다." height="100%">
      {inProgressProjects.length !== 0 ? (
        paginatedProjects.map((project: ProjectType) => (
          <StyledWrapper key={project.prj_id}>
            <StyledTitle>{project.prj_name}</StyledTitle>
            <div>
              <StyledStepNum>{project.step_number + 1}단계</StyledStepNum>
              <StyledProgressBarWrapper>
                <StyledProgressBar>
                  <StyledProgressFiller step={project.step_number} />
                </StyledProgressBar>
              </StyledProgressBarWrapper>
            </div>
            <StyledGoWrapper
              to={`/process/${project.prj_id}/${project.version_number}/${project.step_number}`}>
              Go
            </StyledGoWrapper>
          </StyledWrapper>
        ))
      ) : (
        <StyledEmptyWrapper>
          <StyledEmpty>진행중인 프로젝트가 없습니다.</StyledEmpty>
          <p>There are no ongoing projects!</p>
        </StyledEmptyWrapper>
      )}

      {inProgressProjects.length !== 0 && (
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      )}
    </MainCardLayout>
  )
}

export default InProgress

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 10px 0;
  border-bottom: 0.3px solid ${theme.color.gray700};
`

const StyledEmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
`

const StyledEmpty = styled.span`
  color: ${theme.color.black};
  font-weight: ${theme.fontWeight.bold};
  font-size: 1.5rem;
`

const StyledTitle = styled.span`
  font-size: 1.15rem;
  font-weight: ${theme.fontWeight.semiBold};
  width: 200px;
`

const StyledStepNum = styled.span`
  color: ${theme.color.gray700};
  font-size: 0.9rem;
`

const StyledProgressBarWrapper = styled.div`
  margin-top: 8px;
  width: 150px;
`

const StyledProgressBar = styled.div`
  position: relative;
  height: 8px;
  background-color: ${theme.color.gray100};
  border-radius: 4px;
  overflow: hidden;
`

const StyledProgressFiller = styled.div<{step: number}>`
  width: ${props => (props.step + 1) * 25}%;
  height: 100%;
  background: linear-gradient(to right, #14519f, #1361b0);
  transition: width 0.3s ease-in-out;
`

const StyledGoWrapper = styled(Link)`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100px;
  height: 40px;
  border-radius: 5px;
  background-color: ${theme.color.gray100};
  color: ${theme.color.gray700};
  font-weight: ${theme.fontWeight.bold};
  cursor: pointer;
  transition:
    background-color 0.15s ease,
    color 0.1s ease;

  &:hover {
    background-color: #5358fd;
    color: white;
  }
`
