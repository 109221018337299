import {Link, useNavigate} from 'react-router-dom'
import documentIcon from 'src/assets/icons/Document.png'
import linkIcon from 'src/assets/icons/Link.png'
import Button from 'src/components/button'
import Status from 'src/components/status'
import handleDownload from 'src/hooks/useDownload'
import theme from 'src/styles/theme'
import styled from 'styled-components'
interface File {
  file_id: number
  file_path: string
  file_name: string
}

interface ProjectDetailProps {
  prj_name?: string
  rgst_dtm?: string
  step_number?: number
  prj_description?: string
  version_number?: string
  dev_user_name?: string
  sec_user_name?: string
  file?: File[]
  prj_id?: string
  prj_lnk: string
}

function ProjectDetail({
  prj_name,
  rgst_dtm,
  prj_description,
  step_number,
  version_number,
  dev_user_name,
  sec_user_name,
  file,
  prj_id,
  prj_lnk,
}: ProjectDetailProps) {
  const formattedDate = rgst_dtm ? rgst_dtm.slice(0, 10) : 'No Date'
  const navigate = useNavigate()

  // 상태값에 따라 다른 ui적용
  const getStatusComponent = (step: number) => {
    switch (step) {
      case 0:
        return <Status title="1단계" />
      case 1:
        return <Status title="2단계" />
      case 2:
        return <Status title="3단계" />
      case 3:
        return <Status title="4단계" />
      case 4:
        return <Status title="운영중" />
      default:
        return <Status title="미운영" />
    }
  }
  return (
    <MainContainer>
      <StyledTopWrapper>
        <ProjectDate>{formattedDate} ~</ProjectDate>
        <Button width="70px" height="35px" onClick={() => navigate(`/project/modify/${prj_id}`)}>
          수정
        </Button>
      </StyledTopWrapper>

      <ProjectAlign>
        <ProjectName>{prj_name}</ProjectName>
        {step_number ? getStatusComponent(step_number) : <Status title="1단계" />}
      </ProjectAlign>
      <ProjectDescription>{prj_description}</ProjectDescription>
      <FileList>
        <ProjectAlign>
          <ProjectVersion>{version_number}</ProjectVersion>
          <VersionAlign>
            <img src={documentIcon} alt="logo" width={15} />
            {file?.length ? (
              file.map(file => (
                <FileItem
                  key={file.file_id}
                  onClick={() => handleDownload(file.file_path, file.file_name)}>
                  {file.file_name}
                </FileItem>
              ))
            ) : (
              <p>업로드 파일이 없습니다.</p>
            )}
          </VersionAlign>
        </ProjectAlign>
      </FileList>
      <ItemContainer>
        <DevItem>
          보안담당자 | {sec_user_name} &nbsp;&nbsp;&nbsp; 개발담당자 | {dev_user_name}
        </DevItem>
        <LinkItem to={prj_lnk} target="_blank">
          <img src={linkIcon} alt="logo" width={16} />
          &nbsp;{prj_lnk}
        </LinkItem>
      </ItemContainer>
    </MainContainer>
  )
}

export default ProjectDetail

const MainContainer = styled.div`
  background-color: ${theme.color.white};
  padding: 19px 27px;
  margin: 0 auto;
  border-radius: 12px;
`

const StyledTopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const ProjectDate = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
`
const ProjectName = styled.div`
  font-size: 1.25rem;
  font-weight: ${theme.fontWeight.bold};
  display: flex;
  align-items: center;
`
const ProjectDescription = styled.div`
  font-size: 1.1rem;
`
const ProjectAlign = styled.div`
  display: flex;
  gap: 10px;
  display: flex;
  align-items: center;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const VersionAlign = styled.div`
  gap: 5px;
  display: flex;
  align-items: center;
  color: ${theme.color.black};
  cursor: pointer;
  text-decoration: underline;
  width: fit-content;
  padding: 0 20px;
  height: 30px;
  background-color: ${theme.color.gray50};
  border-radius: 5px;
`

const FileList = styled.div`
  padding-top: 20px;
`

const FileItem = styled.div`
  width: fit-content;
  max-width: 300px;

  font-size: 0.8rem;
  color: black;
  cursor: pointer;
  text-decoration: underline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const DevItem = styled.div`
  font-weight: ${theme.fontWeight.semiBold};
  font-size: 0.9rem;
  opacity: 0.9;
  color: ${theme.color.black};
  padding-top: 10px;
`

const LinkItem = styled(Link)`
  font-weight: ${theme.fontWeight.semiBold};
  font-size: 1rem;
  opacity: 0.9;
  color: ${theme.color.black};
  padding-top: 10px;
  text-decoration: underline;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`

const ProjectVersion = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85px;
  height: 30px;
  border-radius: 6px;
  margin: 10px 0;
  font-size: 0.9rem;
  font-weight: ${theme.fontWeight.bold};
  color: ${theme.color.black};
  background-color: ${theme.color.green300};
  padding-left: 10px;
  padding-right: 10px;
`
