import {Link} from 'react-router-dom'
import lottieData from 'src/assets/lottieJSON/securityIcon.json'
import styled from 'styled-components'
import LottieWrapper from 'src/components/LottieWrapper'
import MainCardLayout from 'src/components/mainCard/Layout'

function SdlManual() {
  return (
    <StyledLinkWrapper to="https://bronzed-lute-37e.notion.site/DAS-SDL-c59e34c4ddc747d3a2c7e331469c93ae">
      <MainCardLayout
        title="SDL Manual"
        subTitle={
          <>
            다스버스 SDL 플랫폼 사용 설명서 입니다. <br />
            플랫폼 규격을 지키며 진행해 주세요.
          </>
        }
        height="110px">
        <StyledLottieWrapper>
          <LottieWrapper lottieData={lottieData} />
        </StyledLottieWrapper>
      </MainCardLayout>
    </StyledLinkWrapper>
  )
}

export default SdlManual

const StyledLinkWrapper = styled(Link)`
  all: unset;
  cursor: pointer;
`

const StyledLottieWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 110px;
`
