import {ErrorMessage} from '@hookform/error-message'
import {FormProvider, useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'
import {useRecoilValue} from 'recoil'
import {handleAddNoti} from 'src/apis/notice'
import Button from 'src/components/button'
import {noticeCreateInput} from 'src/constants/inputForm'
import {authStateAtom} from 'src/stores/user'
import theme from 'src/styles/theme'
import {NoticeCreateType} from 'src/types/notice'
import styled from 'styled-components'

//* 공지사항 생성 page
function NoticeCreate() {
  const getAuthStateAtom = useRecoilValue(authStateAtom)

  const methods = useForm<NoticeCreateType>({mode: 'all'})
  const navigate = useNavigate()

  const handleOnSubmit = async (data: NoticeCreateType) => {
    try {
      const response = await handleAddNoti(data)
      if (response?.resultCode == 200) {
        navigate(`/notice`)
      }

      return true
    } catch (error) {
      return false
    }
  }

  return (
    <FormProvider {...methods}>
      <StyledWrapper>
        <StyledPageTitle>공지사항 생성</StyledPageTitle>
        <p>다스버스 SDL 공지사항을 생성합니다.</p>
        <StyledFormWrapper onSubmit={methods.handleSubmit(handleOnSubmit)}>
          <StyledInputWrapper>
            <StyledInputLabelWrapper>
              <StyledLabel htmlFor="notice_title">공지사항 제목</StyledLabel>
              <ErrorMessage
                name={'notice_title'}
                errors={methods.formState.errors}
                render={({message}) => <StyledError>{message}</StyledError>}
              />
              <StyledLabelInput
                id="notice_title"
                type="notice_title"
                placeholder={
                  noticeCreateInput.find(input => input.type === 'notice_title')?.required
                }
                {...methods.register('notice_title', {
                  required: noticeCreateInput.find(input => input.type === 'notice_title')
                    ?.required,
                })}
              />
            </StyledInputLabelWrapper>

            <StyledInputLabelWrapper>
              <StyledLabel htmlFor="notice_title">공지사항 내용</StyledLabel>
              <ErrorMessage
                name={'notice_description'}
                errors={methods.formState.errors}
                render={({message}) => <StyledError>{message}</StyledError>}
              />
              <StyledTextArea
                id="notice_description"
                placeholder={
                  noticeCreateInput.find(input => input.type === 'notice_description')?.required
                }
                {...methods.register('notice_description', {
                  required: noticeCreateInput.find(input => input.type === 'notice_description')
                    ?.required,
                })}
              />

              <input
                id="rgst_user_id"
                defaultValue={getAuthStateAtom.userId}
                {...methods.register('rgst_user_id')}
                style={{display: 'none'}}
              />
            </StyledInputLabelWrapper>
          </StyledInputWrapper>
          <StyledBottomWrapper>
            <Button type="submit" width="370px">
              등록
            </Button>
          </StyledBottomWrapper>
        </StyledFormWrapper>
      </StyledWrapper>
    </FormProvider>
  )
}

export default NoticeCreate

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100%;
  width: 100%;
  padding: 20px 100px;
`

const StyledPageTitle = styled.span`
  font-weight: ${theme.fontWeight.bold};
  font-size: 1.7rem;
`

const StyledFormWrapper = styled.form`
  gap: 20px;
  margin-top: 20px;
`

const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  gap: 100px;
`

const StyledInputLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledLabel = styled.label`
  font-weight: ${theme.fontWeight.bold};
  font-size: 1.5rem;
`

const StyledLabelInput = styled.input`
  all: unset;
  width: 700px;
  height: 50px;
  padding-left: 10px;

  border: 2px solid ${theme.color.gray50};
  border-radius: 6px;
  font-size: 0.9rem;
`

const StyledTextArea = styled.textarea`
  all: unset;
  width: 98%;
  height: 300px;
  padding: 10px;

  border: 2px solid ${theme.color.gray50};
  border-radius: 6px;
  font-size: 0.9rem;
`

const StyledError = styled.p`
  color: ${theme.color.red};
  font-size: 0.9rem;
  font-weight: ${theme.fontWeight.bold};
`

const StyledBottomWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  margin-top: 40px;
`
