import {useParams} from 'react-router-dom'

import {useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useRecoilValue} from 'recoil'
import {authStateAtom} from 'src/stores/user'
import theme from 'src/styles/theme'
import styled from 'styled-components'
import Button from 'src/components/button'
import SimpleUpload from 'src/components/form/simpleUpload/SimpleUpload'
import Status from 'src/components/status'

interface CommentProps {
  status: string
  userName: string
  rgstDtm?: string
  files?: any
  commentDescription?: string
  handleSubmitForm: (data: any) => void
}

function Comment({status, userName, rgstDtm, commentDescription, handleSubmitForm}: CommentProps) {
  const getAuthStateAtom = useRecoilValue(authStateAtom)
  const param = useParams()
  const [files, setFiles] = useState<FileList | null>(null)
  const {
    control,
    handleSubmit,
    formState: { errors },
} = useForm({
    defaultValues: {
      comment_description: commentDescription || '',
      rgst_user_id: `${getAuthStateAtom.userId}`,
      prj_id: `${param.projectId}`,
      version_number: `${param.versionId}`,
      step_number: `${param.processId}`,
      files: files,
    },
  })
  const getStatusComponent = (step: any) => {
    switch (step) {
      case '보안담당자' && 2:
        return <Status title="보안" />
      case '개발담당자' && 3:
        return <Status title="개발" />
      case '시스템관리자' && 1:
        return <Status title="관리" />
      default:
        return <Status title="보안" />
    }
  }

  const onFilesChange = (files: FileList | null) => {
    setFiles(files)
  }
  const onSubmit = (data: any) => {
    handleSubmitForm({
      ...data,
      files,
    })
  }

  return (
    <CommentWrapper>
      <CommentTitle>Add a comment</CommentTitle>
      <MainContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TitleContainer>
            <TitleItem>{getStatusComponent(status)}</TitleItem>
            <TitleItem>{userName}</TitleItem>
            <RgstItem>
              {rgstDtm !== undefined && `commented {''}`}
              {rgstDtm}
            </RgstItem>
          </TitleContainer>
          <DivingLine />
          <Controller
            name="comment_description"
            control={control}
            rules={{ required: '코멘트가 작성 되지 않았습니다.' }}
            render={({ field }) => (
              <>
                <CommentDescription placeholder="코멘트를 작성해주세요." {...field} />
                {errors.comment_description && (
                  <ErrorMessage>{errors.comment_description.message}</ErrorMessage>
                )}
              </>
            )}
          />
          <ContentContainer>
            <SimpleUpload onFilesChange={onFilesChange} />
            <Button type="submit" width="150px">
              Comment
            </Button>
          </ContentContainer>
        </form>
      </MainContainer>
    </CommentWrapper>
  )
}

export default Comment

const MainContainer = styled.div`
  border: 2px solid ${theme.color.gray500};
  border-radius: 12px;
`
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 3%;
  width: 100%;
  gap: 20px;
  height: 50px;
`
const RgstItem = styled.div`
  color: ${theme.color.gray500};
`
const CommentDescription = styled.textarea`
  display: flex;
  width: 95%;
  margin: 0 auto;
  height: 200px;
  border: 2px solid ${theme.color.gray500};
  border-radius: 10px;
  margin-top: 15px;
  padding: 20px;
  font-size: 1rem;
  color: black;
  resize: none; /* Prevent resizing */
  &::placeholder {
    color: ${theme.color.gray700};
    font-size: 1rem;
  }
`

const TitleItem = styled.div`
  font-size: 1.25rem;
  font-weight: ${theme.fontWeight.bold};
`

const ContentContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  width: 95%;
  margin: 0 auto;
  justify-content: space-between;
`

const DivingLine = styled.div`
  border: 1px solid ${theme.color.gray50};
`

const CommentWrapper = styled.div`
  margin: 0 auto;
  width: 90%;
  margin-top: 100px;
`
const CommentTitle = styled.p`
  font-size: 1.5rem;
  font-weight: ${theme.fontWeight.bold};
  color: ${theme.color.black};
  padding-bottom: 12px;
  padding-left: 16px;
`
const ErrorMessage = styled.p`
  color: ${theme.color.red};
  font-size: 0.875rem;
  padding-left: 5%;
  margin-top: 10px;
`;
