import React, {useState} from 'react'
import removeIcon from 'src/assets/icons/remove.png'
import theme from 'src/styles/theme'
import styled from 'styled-components'

interface SimpleUploadProps {
  onFilesChange: (files: FileList | null) => void
}

function SimpleUpload({onFilesChange}: SimpleUploadProps) {
  const [files, setFiles] = useState<FileList | null>(null)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFiles(event.target.files)
      onFilesChange(event.target.files)
    }
  }

  const handleClearFiles = () => {
    setFiles(null)
    onFilesChange(null)
  }

  const getFileNames = () => {
    if (!files) return '파일을 선택하세요.'
    return Array.from(files)
      .map(file => file.name)
      .join(', ')
  }

  return (
    <Container>
      <UploadArea>
        <HiddenInput type="file" multiple onChange={handleFileChange} id="file-upload" />
        <FileContainer>
          <UploadButton htmlFor="file-upload">업로드</UploadButton>
          <UploadText>{getFileNames()}</UploadText>
          {files && files.length > 0 && (
            <ClearButton onClick={handleClearFiles}>
              <img src={removeIcon} alt="Remove" width={20} />
            </ClearButton>
          )}
        </FileContainer>
      </UploadArea>
    </Container>
  )
}

export default SimpleUpload

const Container = styled.div`
  display: flex;
  width: 100%;
`

const UploadArea = styled.div`
  padding-left: 10px;
  display: flex;
  border: 2px solid ${theme.color.gray100};
  border-radius: 8px;
  height: 42px;
  background-color: ${theme.color.gray100};
  width: 90%;
  padding-right: 10px;
  align-items: center;
`

const HiddenInput = styled.input`
  display: none;
`

const FileContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const UploadButton = styled.label`
  display: inline-block;
  padding: 5px 10px;
  font-size: 1rem;
  color: ${theme.color.black};
  background-color: ${theme.color.green300};
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const UploadText = styled.p`
  font-size: 1rem;
  color: ${theme.color.gray700};
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
`

const ClearButton = styled.button`
  background: none;
  border: none;
  color: ${theme.color.red};
  font-size: 20px;
  cursor: pointer;
  margin-left: 10px;
  padding: 0;
  line-height: 1;
`
