import checkImg from 'src/assets/icons/check.png'
import Button from 'src/components/button'
import theme from 'src/styles/theme'
import {styled} from 'styled-components'
import LabelInput from '.'

interface EmailVerificationProps {
  email: string
  emailValue: string
  emailCodeVerified: boolean
  isEmailVerificationOpen: boolean
  handleSendEmailCode: () => void
  handleAuthEmailVerification: () => void
}

function EmailVerification({
  email,
  emailValue,
  emailCodeVerified,
  isEmailVerificationOpen,
  handleSendEmailCode,
  handleAuthEmailVerification,
}: EmailVerificationProps) {
  return (
    <>
      {email !== emailValue && emailValue && (
        <StyledVerifyWrapper>
          <Button width="70px" height="35px" fontSize="1rem" onClick={handleSendEmailCode}>
            인증
          </Button>
        </StyledVerifyWrapper>
      )}

      {email == emailValue && isEmailVerificationOpen && !emailCodeVerified && (
        <>
          <LabelInput type="auth_code" width="360px" />
          <StyledVerifyWrapper>
            <Button
              width="160px"
              height="35px"
              fontSize="1rem"
              onClick={handleAuthEmailVerification}>
              이메일 코드 인증
            </Button>
          </StyledVerifyWrapper>
        </>
      )}

      <StyledVerifyWrapper>
        <StyledEmailVerify>
          {emailCodeVerified && email == emailValue && (
            <>
              <img src={checkImg} alt="check icon" width={16} />
              <span>이메일이 인증되었습니다.</span>
            </>
          )}
        </StyledEmailVerify>
      </StyledVerifyWrapper>
    </>
  )
}

export default EmailVerification

const StyledVerifyWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

const StyledEmailVerify = styled.span`
  display: flex;
  align-items: center;
  gap: 3px;
  color: ${theme.color.blue700};
  font-weight: ${theme.fontWeight.bold};
  font-size: 0.9rem;
`
