import {useQuery} from '@tanstack/react-query'
import {useEffect} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useNavigate, useParams} from 'react-router-dom'
import {useRecoilValue} from 'recoil'
import {handleProjectDetail} from 'src/apis/project'
import {handleModifyPrj} from 'src/apis/project/create'
import CreatePrjLabelInput from 'src/components/form/labelInput/create'
import CreatePrjLayout from 'src/components/layout/CreatePrjLayout'
import QUERY_KEYS from 'src/constants/queryKey'
import {delYNAtom, devUserAtom, secUserAtom, versionAtom} from 'src/stores/project'
import {authStateAtom} from 'src/stores/user'
import {CreateProjectValuesType} from 'src/types/project/create'

//* 프로젝트 특정 버전 수정 page
//TODO: 타입 수정
function ModifyProject() {
  const {prjId} = useParams()

  const navigate = useNavigate()
  const getVersionAtom = useRecoilValue(versionAtom)
  const getSecUserAtom = useRecoilValue(secUserAtom)
  const getDevUserAtom = useRecoilValue(devUserAtom)
  const getDelYNAtom = useRecoilValue(delYNAtom)
  const getAuthStateAtom = useRecoilValue(authStateAtom)

  const projectData = {
    prj_id: prjId!,
    version_number: getVersionAtom,
  }

  const {data} = useQuery({
    queryKey: [QUERY_KEYS.modifyPrj, prjId],
    queryFn: () => handleProjectDetail(projectData),
  })

  const methods = useForm<CreateProjectValuesType>({
    mode: 'all',
    defaultValues: {
      prj_name: data?.data.prj_name,
    },
  })

  const onSubmit = async (data: any) => {
    const dataFormData = new FormData()

    const filesValue = methods.getValues('files')

    for (const key in data) {
      if (key == 'files') {
        if (filesValue.length == 1) {
          dataFormData.append(key, data[key][0])
        } else {
          for (let i = 0; i < filesValue.length; i++) {
            dataFormData.append(key, data[key][i])
          }
        }
      } else {
        dataFormData.append(key, data[key])
      }
    }

    // 삭제
    const dataDeleteFormData = new FormData()
    if (getDelYNAtom == 'N') {
      dataDeleteFormData.append('prj_id', prjId!)
      dataDeleteFormData.append('updt_user_id', getAuthStateAtom.userId)
      dataDeleteFormData.append('version_number', getVersionAtom)
      dataDeleteFormData.append('del_yn', getDelYNAtom)
    }

    try {
      const response = await handleModifyPrj(
        getDelYNAtom === 'Y' ? dataFormData : dataDeleteFormData,
      )

      if (response?.resultCode == 200) {
        navigate(`/project/detail/${response.data.data.prjId}`)
      }
      return true
    } catch (error) {
      return false
    }
  }

  methods.setValue('prj_id', prjId as any)
  methods.setValue('version_number', getVersionAtom)

  useEffect(() => {
    if (data) {
      if (!methods.getValues('prj_dev_user')) {
        methods.setValue('prj_dev_user', getDevUserAtom)
      }

      if (!methods.getValues('prj_sec_user')) {
        methods.setValue('prj_sec_user', getSecUserAtom)
      }
    }
  }, [data, methods])

  return (
    <FormProvider {...methods}>
      <CreatePrjLayout
        pageTitle="프로젝트 수정"
        submitButtonTitle="수정"
        methods={methods}
        handleSubmit={onSubmit}
        defaultValue={data}>
        <CreatePrjLabelInput
          type="prj_name"
          label="프로젝트 명"
          subTitle="프로젝트 명을 입력해 주세요."
          width="600px"
          defaultValue={data?.data.prj_name}
        />
        <CreatePrjLabelInput
          type="version_number"
          label="버전"
          subTitle="v[Major Version].[Minor Version].[Patches] 형식으로 작성해 주세요."
          width="600px"
        />
        <CreatePrjLabelInput type="prj_id" />
      </CreatePrjLayout>
    </FormProvider>
  )
}

export default ModifyProject
