import {Link} from 'react-router-dom'
import MainCardLayout from 'src/components/mainCard/Layout'
import theme from 'src/styles/theme'
import styled from 'styled-components'

//* 개발 담당자 전용 컴포넌트
function CreateCard() {
  return (
    <MainCardLayout title="Create" subTitle="개발 담당자는 프로젝트 생성 권한이 부여됩니다.">
      <StyledBtnWrapper to="/create">
        <StyledTitle>프로젝트 생성</StyledTitle>
        <StyledSubTitle>새로운 프로젝트를 생성합니다</StyledSubTitle>
      </StyledBtnWrapper>

      <StyledBtnWrapper to="/createVersion" bgColor={`${theme.color.blue100}`}>
        <StyledTitle>버전 생성</StyledTitle>
        <StyledSubTitle>특정 프로젝트의 버전을 생성합니다</StyledSubTitle>
      </StyledBtnWrapper>
    </MainCardLayout>
  )
}

export default CreateCard

const StyledBtnWrapper = styled(Link)<{bgColor?: string}>`
  all: unset;
  display: flex;
  flex-direction: column;

  border-radius: 10px;
  padding: 12px 20px;
  background-color: ${props => (props.bgColor ? props.bgColor : '#cdd7f7')};
  margin-top: 10px;
  cursor: pointer;
`

const StyledTitle = styled.span`
  font-weight: ${theme.fontWeight.semiBold};
  font-size: 1.2rem;
`

const StyledSubTitle = styled.span`
  font-weight: ${theme.fontWeight.light};
  font-size: 0.9rem;
  color: #323232b0;
`
