import {ReactNode} from 'react'
import Button from 'src/components/button'
import theme from 'src/styles/theme'
import styled from 'styled-components'

interface ModalProps {
  title: string
  leftBtnTitle?: string
  rightBtnTitle?: string
  children: ReactNode
  onClick: () => void
  actionClick?: (e: any) => void
}

//* 공통 modal
//TODO: react portal 적용
function Modal({title, leftBtnTitle, rightBtnTitle, children, onClick, actionClick}: ModalProps) {
  return (
    <>
      <StyledWrapper>
        <StyledInWrapper>
          <StyledTitle>{title}</StyledTitle>
          <StyledSubTitle>여러 번의 검토 후 버튼을 눌러주세요</StyledSubTitle>
          <StyledItemWrapper>{children}</StyledItemWrapper>

          <StyledBtnWrapper>
            <Button width="170px" onClick={onClick}>
              {leftBtnTitle ? leftBtnTitle : '취소'}
            </Button>
            <Button
              type="submit"
              width="170px"
              bgColor={`${theme.color.red}`}
              onClick={actionClick}>
              {rightBtnTitle ? rightBtnTitle : '삭제'}
            </Button>
          </StyledBtnWrapper>
        </StyledInWrapper>
      </StyledWrapper>
    </>
  )
}

export default Modal

const StyledWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(5px);
  z-index: 100;
`

const StyledInWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 400px;
  min-height: 350px;
  height: auto;
  background-color: #000000fd;
  color: ${theme.color.white};
  font-weight: ${theme.fontWeight.bold};
  border-radius: 6px;
`

const StyledTitle = styled.span`
  font-size: 1.3rem;
`

const StyledSubTitle = styled.p`
  color: ${theme.color.gray700};
  font-weight: ${theme.fontWeight.light};
  margin-top: 5px;
`

const StyledItemWrapper = styled.div`
  margin: 20px 0;
  font-size: 1.05rem;
`

const StyledBtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 40px;
`
