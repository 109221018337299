import {Link} from 'react-router-dom'
import theme from 'src/styles/theme'
import styled from 'styled-components'

function NotFound() {
  return (
    <StyledWrapper>
      <StyledInWrapper>
        <StyledContentWrapper>
          <StyledIntroText>페이지를 찾을 수 없어요.</StyledIntroText>
          <StyledSubSummary>
            <span>요청하신 결과를 찾을 수 없어요.</span>
            <span>입력한 URL이 올바른지 확인해주세요.</span>
          </StyledSubSummary>
        </StyledContentWrapper>
        <StyledGoToMainWrapper to="/">
          <span>메인으로 돌아가기</span>
        </StyledGoToMainWrapper>
      </StyledInWrapper>
    </StyledWrapper>
  )
}

export default NotFound

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
`

const StyledInWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
`

const StyledIntroText = styled.h1`
  font-size: 2rem;
  font-weight: ${theme.fontWeight.bold};
  letter-spacing: 0.02px;
`

const StyledSubSummary = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: ${theme.fontWeight.light};
  margin: 1rem 0 1.6rem 0;
`

const StyledGoToMainWrapper = styled(Link)`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  padding: 0 10px;
  height: 50px;
  width: 400px;
  background-color: ${theme.color.background};
`
