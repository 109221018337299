//* auth
export const ERROR_EMAIL_CHECK = '이메일 형식으로 작성해 주세요.'
export const ERROR_EMAIL_EXIST = '이미 사용중인 이메일입니다.'
export const ERROR_EMAIL_EMPTY = '이메일을 입력해 주세요.'

export const ERROR_EMAIL_CODE_EMPTY = '이메일로 전송된 인증번호를 입력해 주세요.'
export const ERROR_EMAIL_CODE_CHECK = '이메일 인증은 필수입니다.'

export const ERROR_PASSWORD_CHECK = '비밀번호가 일치하지 않습니다.'
export const ERROR_PASSWORD_VALIDATION = '영어와 특수문자를 포함해 8자 이상 입력해 주세요.'
export const ERROR_PASSWORD_EMPTY = '비밀번호를 입력해 주세요.'
export const ERROR_PASSWORD_SECOND_EMPTY = '비밀번호를 한번 더 입력해 주세요.'

export const ERROR_PASSWORD_CURRENT_EMPTY = '현재 비밀번호를 입력해 주세요.'
export const ERROR_PASSWORD_CURRENT = '현재 비밀번호가 틀립니다.'
export const ERROR_PASSWORD_NEW_PASSWORD_EMPTY = '새로운 비밀번호를 입력해 주세요.'
export const ERROR_PASSWORD_NEW_PASSWORD_CURRENT = '현재 비밀번호와 동일합니다.'

export const ERROR_NICKNAME_EMPTY = '이름을 입력해 주세요.'
export const NICKNAME_STANDARD = /^[A-Za-z0-9ㄱ-ㅎ가-힣]+$/
export const ERROR_NICKNAME_VALIDATION = '이름에 특수문자를 포함할 수 없습니다.'

export const EMAIL_STANDARD = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
// 영문, 숫자, 특수기호 포함 8자 이상
export const PASSWORD_STANDARD = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/

//* project
export const ERROR_PROJECT_NAME_EMPTY = '프로젝트 이름을 입력해 주세요.'
export const ERROR_PROJECT_CHOOSE_EMPTY = '프로젝트를 선택해 주세요.'
export const ERROR_PROJECT_DESCRIPTION_EMPTY = '프로젝트 설명을 작성해 주세요.'
export const ERROR_PROJECT_VERSION_EMPTY = '프로젝트의 버전을 작성해 주세요.'
export const ERROR_VERSION_LATEST_CHECK = '기존의 버전보다 낮게 설정할 수 없습니다.'
export const ERROR_PROJECT_LINK_EMPTY = '피그마 링크를 첨부해 주세요.'
export const ERROR_PROJECT_DEV_USER_EMPTY = '개발 담당자를 등록해 주세요.'
export const ERROR_PROJECT_SEC_USER_EMPTY = '보안 담당자를 등록해 주세요.'
export const ERROR_PROJECT_FILE_EMPTY =
  '프로젝트 설명서, 요구사항 정의서 등의 파일 업로드가 필요합니다.'

export const LINK_STANDARD = /(https:\/\/[^\s]+)/g
export const ERROR_PROJECT_LINK_STANDARD = '올바른 링크를 등록해 주세요.'

export const VERSION_STANDARD = /^v[0-9]{1,2}\.[0-9]{1,2}\.[0-9]{1,2}$/
export const ERROR_VERSION_STANDARD_STANDARD = '버전의 양식에 맞추어 등록해 주세요.'

//* notice
export const ERROR_NOTICE_TITLE_EMPTY = '공지사항 제목을 입력해 주세요.'
export const ERROR_NOTICE_DESCRIPTION_EMPTY = '공지사항 내용을 입력해 주세요.'
