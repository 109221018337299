import {useState} from 'react'
import theme from 'src/styles/theme'
import styled from 'styled-components'

interface StepProps {
  step: number
  handleClick: any
}

const steps = [
  {
    label: '요구사항',
    step: 0,
  },
  {
    label: '설계서 업로드',
    step: 1,
  },
  {
    label: '코드 업로드',
    step: 2,
  },
  {
    label: '모의해킹',
    step: 3,
  },
]

function Progress({handleClick = () => void {}, step}: StepProps) {
  const [activeStep, setActiveStep] = useState<number>(step)

  return (
    <StepContainer>
      {steps.map(({step, label}) => (
        <StepWrapper key={step}>
          <StepStyle
            step={
              activeStep === step ? 'working' : activeStep > step ? 'completed' : 'inCompleted'
            }>
            {activeStep > step ? (
              <CheckMark>L</CheckMark>
            ) : activeStep === step ? (
              <StepCount>·</StepCount>
            ) : (
              ''
            )}
          </StepStyle>
          <StepLabel onClick={() => handleClick({id: step})} disabled={step > activeStep}>
            {label} &gt;
          </StepLabel>
        </StepWrapper>
      ))}
    </StepContainer>
  )
}

export default Progress

const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 24px;
  position: relative;
  height: 105px;
  background: ${theme.color.white};
  border-radius: 12px;
`

const StepWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  gap: 4px;
`

const StepStyle = styled.div<{step: string}>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({step}) =>
    step === 'completed' ? `${theme.color.blue700}` : 'working' ? `${theme.color.white}` : ''};

  border: 1px solid
    ${({step}) =>
      step === 'completed'
        ? 'none'
        : step === 'working'
          ? `${theme.color.blue700}`
          : `${theme.color.gray500}`};
  display: flex;
  justify-content: center;
  align-items: center;
`

const StepCount = styled.span`
  font-size: 2rem;
  color: ${theme.color.blue700};
  transform: translate(0%, -2.5%);
`

const StepLabel = styled.button<{disabled: boolean}>`
  font-weight: ${theme.fontWeight.bold};
  color: ${props => (props.disabled ? theme.color.gray300 : theme.color.black)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`

const CheckMark = styled.div`
  font-size: 0.5rem;
  color: ${theme.color.white};
  -webkit-transform: scaleX(-1) rotate(-46deg);
  transform: scaleX(-1) rotate(-46deg);
`
