import {useQuery} from '@tanstack/react-query'
import {useEffect} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {useSetRecoilState} from 'recoil'
import {handleProjectDetail, handleProjectHistoryList} from 'src/apis/project'
import Progress from 'src/components/progress/Progress'
import ProjectDetail from 'src/components/ProjectDetail'
import Table from 'src/components/table/Table'
import {ProjectHistoryData} from 'src/constants/ColumnData'
import QUERY_KEYS from 'src/constants/queryKey'
import {devUserAtom, secUserAtom} from 'src/stores/project'
import theme from 'src/styles/theme'
import {ProjectHistoryType, ProjectListType} from 'src/types/project'
import styled from 'styled-components'

//* 프로젝트 상세 page
//TODO: projectHistoryData prj_id 변경
function Detail() {
  const {prjId} = useParams()
  const navigate = useNavigate()
  const param = useParams()
  const location = useLocation()
  const version = {...location.state}
  const setDevUserAtom = useSetRecoilState(devUserAtom)
  const setSecUserAtom = useSetRecoilState(secUserAtom)

  const projectHistoryData: ProjectHistoryType = {
    prj_id: parseInt(`${param.projectId}`),
  }

  const projectData: ProjectListType = {
    prj_id: prjId!,
    version_number: `${version.versionNumber}`,
  }

  const {data: prjHistoryList} = useQuery({
    queryKey: [QUERY_KEYS.prjHistoryList, projectHistoryData],
    queryFn: () => handleProjectHistoryList(projectHistoryData),
  })

  const {data: prjDetail} = useQuery({
    queryKey: [QUERY_KEYS.prjDetail, projectData],
    queryFn: () => handleProjectDetail(projectData),
  })

  const devUserIdArray = prjDetail?.data.dev_user_id.split(',').map(Number)
  const secUserArray = prjDetail?.data.sec_user.split(',').map(Number)

  useEffect(() => {
    setDevUserAtom(devUserIdArray)
    setSecUserAtom(secUserArray)
  }, [devUserIdArray, secUserArray])

  interface StepProps {
    id?: any
    handleClick?: any
  }

  const handleRowSelect = (rowData: any) => {
    navigate(
      `/process/${rowData?.original?.prj_id}/${rowData?.original?.version_number}/${rowData.original.step_number}`,
    )
  }
  // process 화면으로 이동
  const processDetail = async ({id}: StepProps) => {
    if (prjDetail !== undefined) {
      navigate(`/process/${prjDetail?.data?.prj_id}/${prjDetail?.data?.version_number}/${id}`)
    }
  }

  const projectHistory = Array.isArray(prjHistoryList?.data) ? prjHistoryList.data : []
  return (
    <StyledWrapper>
      <StyledProjectWrapper>
        {prjDetail !== undefined && (
          <>
            <StyledProjectName>{prjDetail.data?.prj_name}</StyledProjectName>
            <Progress step={prjDetail?.data?.step_number} handleClick={processDetail} />
            <StyledMiddleWrapper></StyledMiddleWrapper>
            <ProjectDetail
              prj_name={prjDetail?.data?.prj_name}
              rgst_dtm={prjDetail?.data?.rgst_dtm}
              step_number={prjDetail?.data?.step_number}
              prj_description={prjDetail?.data?.prj_description}
              version_number={prjDetail?.data?.version_number}
              file={prjDetail?.data?.file}
              dev_user_name={prjDetail?.data?.dev_user_name}
              sec_user_name={prjDetail?.data?.sec_user_name}
              prj_id={prjId!}
              prj_lnk={prjDetail?.data?.prj_lnk}
            />
          </>
        )}
        <MiddleContainer></MiddleContainer>
      </StyledProjectWrapper>
      <Table
        columns={ProjectHistoryData}
        data={projectHistory}
        title="History"
        subTitle="해당 프로젝트의 버전 히스토리"
        onRowSelect={handleRowSelect}
      />
    </StyledWrapper>
  )
}

export default Detail

const StyledWrapper = styled.div`
  padding-top: 30px;
  background-color: ${theme.color.background};
`

const StyledProjectWrapper = styled.div`
  margin: 0 auto;
  width: 90%;
`

const StyledProjectName = styled.p`
  font-size: 1.875rem;
  padding-bottom: 30px;
  font-weight: ${theme.fontWeight.semiBold};
`

const StyledMiddleWrapper = styled.p`
  height: 30px;
`

const MiddleContainer = styled.p`
  height: 30px;
`
