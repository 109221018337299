import {FormProvider, useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'
import {useRecoilValue} from 'recoil'
import {handleProjectLatestVersion} from 'src/apis/project'
import {handleCreatePrjVersion} from 'src/apis/project/create'
import CreatePrjLabelInput from 'src/components/form/labelInput/create'
import CreatePrjLayout from 'src/components/layout/CreatePrjLayout'
import {ERROR_VERSION_LATEST_CHECK} from 'src/constants/inputMessage'
import {prjIdAtom} from 'src/stores/project'
import {CreateProjectValuesType} from 'src/types/project/create'
import compareVersions from 'src/utils/compareVersions'

//* 프로젝트 버전 생성 page
function CreateVersion() {
  const methods = useForm<CreateProjectValuesType>({mode: 'all'})
  const navigate = useNavigate()
  const getPrjIdAtom = useRecoilValue(prjIdAtom)

  const onSubmit = async (data: any) => {
    const inputVersion = methods.watch('version_number')

    try {
      const response = await handleProjectLatestVersion(getPrjIdAtom)
      if (compareVersions(inputVersion, response.data.version_number) === -1) {
        methods.setError(
          'version_number',
          {message: ERROR_VERSION_LATEST_CHECK},
          {shouldFocus: true},
        )
      }
    } catch (error) {
      console.log(error)
    }

    const dataFormData = new FormData()
    const filesValue = methods.getValues('files')

    for (const key in data) {
      if (key == 'files') {
        if (filesValue.length == 1) {
          dataFormData.append(key, data[key][0])
        } else {
          for (let i = 0; i < filesValue.length; i++) {
            dataFormData.append(key, data[key][i])
          }
        }
      } else {
        dataFormData.append(key, data[key])
      }
    }

    try {
      const response = await handleCreatePrjVersion(dataFormData)

      if (response?.resultCode == 200) {
        navigate('/main')
      }
      return true
    } catch (error) {
      return false
    }
  }

  return (
    <FormProvider {...methods}>
      <CreatePrjLayout
        pageTitle="버전 생성"
        submitButtonTitle="등록"
        methods={methods}
        handleSubmit={onSubmit}>
        <CreatePrjLabelInput
          type="prj_id"
          label="프로젝트 선택"
          subTitle="프로젝트를 선택해 주세요."
          width="600px"
        />
        <CreatePrjLabelInput
          type="version_number"
          label="버전"
          subTitle="v[Major Version].[Minor Version].[Patches] 형식으로 작성해 주세요."
          width="600px"
        />
      </CreatePrjLayout>
    </FormProvider>
  )
}

export default CreateVersion
