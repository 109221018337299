import theme from 'src/styles/theme'

export const projectStatus = [
  {title: '검토중', bgColor: `${theme.color.green300}`, color: `${theme.color.black}`},
  {title: '운영중', bgColor: `${theme.color.orange100}`, color: `${theme.color.black}`},
  {title: '미운영', bgColor: `${theme.color.blue100}`, color: `${theme.color.black}`},

  {title: '보안', bgColor: `${theme.color.green300}`, color: `${theme.color.black}`},
  {title: '개발', bgColor: `${theme.color.orange100}`, color: `${theme.color.black}`},
  {title: '관리', bgColor: `${theme.color.orange300}`, color: `${theme.color.black}`},

  {title: '1단계', bgColor: `${theme.color.pink}`},
  {title: '2단계', bgColor: `${theme.color.blue300}`},
  {title: '3단계', bgColor: `${theme.color.green500}`},
  {title: '4단계', bgColor: `${theme.color.purple}`},
]
