const theme = {
  color: {
    white: '#ffffff',

    black: '#000000',
    background: '#F8F9FD',

    red: '#FF3E3E',
    orange100: '#FCECCA',
    orange300: '#FFCDA7',
    orange500: '#ffae6f',
    green100: '#E3FFEB',
    green300: '#D6F7CD',
    green500: '#00D26A',
    blue100: '#CDE3F7',
    blue300: '#56C2DB',
    blue700: '#0969DA',
    yellow: '#FFAD00',
    purple100: "#cdd7f7",
    purple: '#A35BFF',
    pink: '#FFA599',
    teal: '#3EC695',

    gray50: '#f1f1f1',
    gray100: '#F4F7F9',
    gray300: '#B5B5B5',
    gray500: '#C6C6C6',
    gray700: '#777777',
  },
  fontWeight: {
    thin: 100,
    light: 300,
    normal: 500,
    bold: 700,
    semiBold: 600,
  },
}

export default theme
