import {AxiosError} from 'axios'
import {
  CommonFormValuesType,
  EmailVerifyValuesType,
  SignInFormValuesType,
  SignNewPasswordType,
  SignUpFormValuesType,
  UserResponseType,
} from 'src/types/user'
import {instance, instanceWithToken, post} from '.'

// 로그인
export async function handleLogin(data: SignInFormValuesType) {
  try {
    const response = await post<UserResponseType>('/user/signIn', data)
    return response.data
  } catch (e: unknown) {
    e instanceof AxiosError
      ? alert(e.response?.data.message || e.message)
      : console.log('알 수 없는 오류가 발생하였습니다:', e)
  }
}

// 회원가입
export async function handleSignup(data: SignUpFormValuesType) {
  try {
    const response = await post('/user/signUp', data)
    return response.data
  } catch (e: unknown) {
    e instanceof AxiosError
      ? alert(e.response?.data.message || e.message)
      : console.log('알 수 없는 오류가 발생하였습니다:', e)
  }
}

// 새로운 비밀번호
export async function handleNewPassword(data: SignNewPasswordType) {
  try {
    const response = await instance.put('/user/modify', data)
    return response.data
  } catch (e: unknown) {
    e instanceof AxiosError
      ? alert(e.response?.data.message || e.message)
      : console.log('알 수 없는 오류가 발생하였습니다:', e)
  }
}

// 사용자 이메일 인증코드 전송
export async function handleSendEmailAuth(data: CommonFormValuesType) {
  try {
    const response = await post('/user/sendEmailAuth', data)
    return response.data
  } catch (e: unknown) {
    e instanceof AxiosError
      ? alert(e.response?.data.message || e.message)
      : console.log('알 수 없는 오류가 발생하였습니다:', e)
  }
}

// 사용자 이메일 인증
export async function handleAuthEmailCode(data: EmailVerifyValuesType) {
  try {
    const response = await post('/user/authEmailCode', data)
    return response.data
  } catch (e: unknown) {
    e instanceof AxiosError
      ? alert(e.response?.data.message || e.message)
      : console.log('알 수 없는 오류가 발생하였습니다:', e)
  }
}

// 비밀번호 찾기
export async function handleForgotPassword(data: CommonFormValuesType) {
  const response = await instance.post('/user/frgtEml', data)
  return response.data
}

// 유저 리스트 출력
export async function handleDvList(userLevel?: string) {
  const response = await instanceWithToken.get(
    userLevel ? `/user/dvList?user_level=${userLevel}` : `/user/dvList`,
  )
  return response.data
}
