import {useNavigate} from 'react-router-dom'
import Button from 'src/components/button'
import NoticeTable from 'src/components/table/notice'
import styled from 'styled-components'

//* 공지사항 page
function Notice() {
  const navigate = useNavigate()

  return (
    <>
      <StyledBtnWrapper>
        <Button width="100px" height="40px" onClick={() => navigate('/notice/create')}>
          새 글 쓰기
        </Button>
      </StyledBtnWrapper>
      <NoticeTable title="공지" subTitle="SDL 공지" />
    </>
  )
}

export default Notice

const StyledBtnWrapper = styled.div`
  position: absolute;
  right: 70px;
  top: 150px;
`
