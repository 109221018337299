import {useQuery, useQueryClient} from '@tanstack/react-query'
import {useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useRecoilValue} from 'recoil'
import {
  handleAcceptProcess,
  handleCreateComment,
  handleDeleteComment,
  handleProcessInfo,
  handleRegistProcess,
  handleUpdateComment,
} from 'src/apis/process'
import {handleProjectDetail} from 'src/apis/project'
import documentIcon from 'src/assets/icons/Document.png'
import linkIcon from 'src/assets/icons/Link.png'
import Button from 'src/components/button'
import Comment from 'src/components/comment'
import CommentInfo from 'src/components/comment/commentInfo'
import SimpleUpload from 'src/components/form/simpleUpload/SimpleUpload'
import Tab from 'src/components/tab/Tab'
import QUERY_KEYS from 'src/constants/queryKey'
import handleDownload from 'src/hooks/useDownload'
import {DataTab} from 'src/pages/project/process/data'
import {authStateAtom} from 'src/stores/user'
import theme from 'src/styles/theme'
import {ProcessInfoType} from 'src/types/process'
import {ProjectListType} from 'src/types/project'
import styled from 'styled-components'

function Process() {
  const navigate = useNavigate()
  const param = useParams()
  const getAuthStateAtom = useRecoilValue(authStateAtom)
  const queryClient = useQueryClient()
  const [stepLink, setStepLink] = useState('')
  const [stepDescription, setStepDescription] = useState('')
  const [files, setFiles] = useState<FileList | null>(null)
  const [linkError, setLinkError] = useState(false)

  interface ProcessProps {
    index?: number
  }
  // 링크 유효성 검사
  const isValidLink = (link: string) => {
    const regex = /^https?:\/\/(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\/.*)?$/
    return regex.test(link)
  }
  // 운영중, 운영중지 상태인 경우는 마지막단계(모의해킹)로 이동
  const selectedProcess = DataTab.find(
    obj =>
      obj.projectId ===
      (param?.processId === '4' ? '3' : param?.processId === '5' ? '3' : param?.processId),
  )?.projectId

  const handleClickProcessTab = async ({index}: ProcessProps) => {
    navigate(`/process/${param?.projectId}/${param?.versionId}/${index}`)
    window.location.reload()
  }

  const onFilesChange = (files: FileList | null) => {
    setFiles(files)
  }

  // 코멘트 등록
  const createComment = async (data: {files: FileList; [key: string]: any}) => {
    const formData = new FormData()

    // 파일 리스트를 FormData에 추가
    if (data.files) {
      Array.from(data.files).forEach(file => {
        formData.append('files', file)
      })
    }

    // 나머지 데이터를 FormData에 추가
    for (const key in data) {
      if (data.hasOwnProperty(key) && key !== 'files') {
        formData.append(key, data[key])
      }
    }
    try {
      const response = await handleCreateComment(formData)
      if (response?.resultCode === 200) {
        queryClient.invalidateQueries(processInfo)
        return true
      }
    } catch (error) {
      console.error('Error creating comment:', error)
      return false
    }
  }

  const handleProcessRegist = async (e: {preventDefault: () => void}) => {
    e.preventDefault()
    const formData = new FormData()
    if (!stepLink || !stepDescription || !files?.length) {
      return
    }
    if (!isValidLink(stepLink)) {
      setLinkError(true)
      return
    }
    if (files) {
      Array.from(files).forEach(file => {
        formData.append('files', file)
      })
    }
    // 나머지 데이터를 FormData에 추가
    formData.append('prj_id', `${param.projectId}`)
    formData.append('version_number', `${param.versionId}`)
    formData.append('step_number', `${param.processId}`)
    formData.append('step_lnk', stepLink)
    formData.append('step_description', stepDescription)
    try {
      const response = await handleRegistProcess(formData)
      if (response?.resultCode === 200) {
        queryClient.invalidateQueries(processInfo)
      }
    } catch (error) {
      console.error('Error registering process:', error)
    }
  }

  // 코멘트 업데이트
  const handleUpdateClick = async (commentId: number, newDescription: any) => {
    try {
      const response = await handleUpdateComment({
        comm_id: commentId,
        comment_description: newDescription,
      })
      if (response?.resultCode === 200) {
        queryClient.invalidateQueries(processInfo)
        return true
      }
    } catch (error) {
      console.error('Error updating comment:', error)
      return false
    }
  }

  // 코멘트 삭제
  const handleDeleteClick = async (commentId: number) => {
    try {
      const response = await handleDeleteComment(commentId)
      if (response?.resultCode === 200) {
        queryClient.invalidateQueries(processInfo)
        return true
      }
    } catch (error) {
      console.error('Error deleting comment:', error)
      return false
    }
  }

  // 프로세스 단계별 승인
  const handleAcceptProcessClick = async (stepId: any) => {
    try {
      const response = await handleAcceptProcess(`${processInfo.data.step_id}`)
      if (response?.resultCode === 200) {
        window.location.reload()
        return true
      }
    } catch (error) {
      console.error('Error deleting comment:', error)
      return false
    }
  }

  // 페이지 상단 프로젝트 명/버전
  const projectData: ProjectListType = {
    prj_id: parseInt(`${param.projectId}`),
    version_number: `${param.versionId}`,
  }

  const processData: ProcessInfoType = {
    prj_id: parseInt(`${param.projectId}`),
    version_number: `${param.versionId}`,
    step_number: parseInt(`${param.processId}`),
  }

  const {data: processInfo} = useQuery({
    queryKey: [QUERY_KEYS.procInfo, selectedProcess],
    queryFn: () => handleProcessInfo(processData),
  })

  const {data: projectDetail} = useQuery({
    queryKey: [QUERY_KEYS.prjDetail, processData],
    queryFn: () => handleProjectDetail(projectData),
  })

  const isDisabled =
    stepLink === '' ||
    stepDescription === '' ||
    files === null ||
    getAuthStateAtom.userLevel !== 3 ||
    linkError ||
    projectDetail?.data.step_number !== parseInt(`${param.processId}`)

  const acceptButtonDisabled =
    getAuthStateAtom.userLevel !== 2 ||
    !(processInfo?.data.file && projectDetail?.data.step_number === parseInt(`${param.processId}`))

  useEffect(() => {
    if (stepLink && !isValidLink(stepLink)) {
      setLinkError(true)
    } else {
      setLinkError(false)
    }
  }, [stepLink])

  return (
    <MainContainer>
      <ProcessConatiner>
        {(processInfo && projectDetail) !== undefined && (
          <>
            <ProjectInfoWrapper>
              <Title>{projectDetail.data.prj_name}</Title>
              <ProjectVersion>{projectDetail.data.version_number}</ProjectVersion>
            </ProjectInfoWrapper>
            <StyledWrapper>
              <Tab data={DataTab} selected={selectedProcess} handleClick={handleClickProcessTab} />
            </StyledWrapper>
            <ProcessWrapper>
              <form onSubmit={handleProcessRegist}>
                <ProcessFileWrapper>
                  <ProcessAlign>
                    <LiskTitle>
                      파일<StyledRequired>*</StyledRequired>
                    </LiskTitle>
                    <LiskSubTitle>프로젝트 설계서와 관련 된 파일을 업로드 해주세요.</LiskSubTitle>
                    {processInfo.data.file?.length ? (
                      <LiskWrapper>
                        <FileWrapper>
                          <img src={documentIcon} alt="logo" width={20} />
                          {processInfo.data.file.map((file: any) => (
                            <FileItem
                              key={file.file_id}
                              onClick={() => handleDownload(file.file_path, file.file_name)}>
                              {file.file_name}
                            </FileItem>
                          ))}
                        </FileWrapper>
                      </LiskWrapper>
                    ) : (
                      <StyledFileInput>
                        <SimpleUpload onFilesChange={onFilesChange} />
                      </StyledFileInput>
                    )}
                  </ProcessAlign>
                  <ProcessAlign>
                    <LiskTitle>
                      링크<StyledRequired>*</StyledRequired>
                    </LiskTitle>
                    <LiskSubTitle>피그마 링크를 첨부해 주세요.</LiskSubTitle>
                    {processInfo !== undefined && (
                      <>
                        {processInfo.data.step_lnk?.length ? (
                          <LiskWrapper>
                            <FileWrapper>
                              <img src={linkIcon} alt="logo" width={20} />
                              <LinkItem to={`${processInfo.data.step_lnk}`} target="_blank">
                                {processInfo.data.step_lnk}
                              </LinkItem>
                            </FileWrapper>
                          </LiskWrapper>
                        ) : (
                          <>
                            <StyledLinkInput
                              value={stepLink}
                              onChange={e => setStepLink(e.target.value)}
                              placeholder="https://www.figma.com"></StyledLinkInput>
                            {linkError && (
                              <ErrorText>
                                링크 형식이 올바르지 않습니다. 예: https://www.example.com
                              </ErrorText>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </ProcessAlign>
                </ProcessFileWrapper>
                <LiskTitle>
                  프로세스 설명<StyledRequired>*</StyledRequired>
                </LiskTitle>
                {processInfo.data.step_lnk?.length ? (
                  <ProcessDescriptionDiv>{processInfo.data.step_description}</ProcessDescriptionDiv>
                ) : (
                  <ProcessDescription
                    value={stepDescription}
                    onChange={e => setStepDescription(e.target.value)}
                    placeholder="프로세스 관련 내용을 작성 해 주세요.">
                    {processInfo.data.step_description}
                  </ProcessDescription>
                )}
                <ButtonWrapper hasError={getAuthStateAtom.userLevel !== 3}>
                  {getAuthStateAtom.userLevel !== 3 && (
                    <ErrorMessage>※ 등록은 개발담당자만 처리할 수 있습니다.</ErrorMessage>
                  )}
                  {(stepLink !== '' && stepDescription !== '' && files !== null) ||
                    (getAuthStateAtom.userLevel === 3 &&
                      projectDetail?.data.step_number === parseInt(`${param.processId}`) &&
                      processInfo.data.step_number === undefined && (
                        <ErrorMessage>
                          ※ 모든 필수 정보를 입력하셔야 합니다. 필수 항목을 확인해 주세요.
                        </ErrorMessage>
                      ))}
                  {projectDetail?.data.step_number !== parseInt(`${param.processId}`) &&
                    projectDetail?.data.step_number < parseInt(`${param.processId}`) && (
                      <ErrorMessage>※ 이전 프로세스의 보안담당자 승인이 필요합니다.</ErrorMessage>
                    )}
                  <Button
                    type="submit"
                    width="20%"
                    height="60px"
                    status={isDisabled ? 'Disabled' : 'Enabled'}
                    style={isDisabled ? {cursor: 'not-allowed'} : {}}
                    disabled={isDisabled}>
                    등록
                  </Button>
                </ButtonWrapper>
              </form>
              {parseInt(`${param.processId}`) === 0 && (
                <LiskWrapper>
                  <LiskTitle>리스크 체크</LiskTitle>
                  <LiskSubTitle>리스크 체크 후 Comments를 남겨주세요.</LiskSubTitle>
                  <FileContainer
                    onClick={() =>
                      handleDownload(
                        processInfo.data.lstc_file_path,
                        processInfo.data.lstc_file_name,
                      )
                    }>
                    <img src={documentIcon} alt="logo" width={20} />
                    <FileItem>리스크체크.pdf</FileItem>
                  </FileContainer>
                </LiskWrapper>
              )}
            </ProcessWrapper>
            <CommentListWrapper>
              {processInfo.data.commentList !== undefined &&
                processInfo.data.commentList.map((commentList: any) => (
                  <CommentInfo
                    key={commentList.comm_id}
                    status={commentList.user_role}
                    userName={commentList.user_name}
                    rgstDtm={commentList.rgst_dtm}
                    updtDtm={commentList.updt_dtm}
                    files={commentList.files.map((file: any) => (
                      <FileItem
                        key={file.comm_file_id}
                        onClick={() => handleDownload(file.file_path, file.file_name)}>
                        {file.file_name}
                      </FileItem>
                    ))}
                    commentDescription={commentList.comment_description}
                    handleUpdateClick={handleUpdateClick}
                    handleDeleteClick={handleDeleteClick}
                    commentId={commentList.comm_id}></CommentInfo>
                ))}
            </CommentListWrapper>
            <Comment
              status={getAuthStateAtom.userLevel}
              userName={getAuthStateAtom.userName}
              handleSubmitForm={createComment}></Comment>
            <ButtonWrapper hasError={getAuthStateAtom.userLevel !== 2}>
              {getAuthStateAtom.userLevel !== 2 &&
                projectDetail.data.step_number <= parseInt(`${param.processId}`) && (
                  <ErrorMessage>※ 승인은 보안담당자만 처리할 수 있습니다.</ErrorMessage>
                )}
              {getAuthStateAtom.userLevel !== 2 &&
                projectDetail.data.step_number > processInfo.data.step_number && (
                  <ErrorMessage>※ 승인 되었습니다.</ErrorMessage>
                )}
              {processInfo.data.file !== undefined &&
              projectDetail.data.step_number === parseInt(`${param.processId}`) ? (
                <Button
                  type="submit"
                  width="20%"
                  height="60px"
                  onClick={handleAcceptProcessClick}
                  status={acceptButtonDisabled ? 'Disabled' : 'Enabled'}
                  style={acceptButtonDisabled ? {cursor: 'not-allowed'} : {}}
                  disabled={acceptButtonDisabled}>
                  승인
                </Button>
              ) : (
                <>
                  <Button
                    type="submit"
                    width="20%"
                    height="60px"
                    onClick={handleAcceptProcessClick}
                    disabled={true}
                    status="Disabled"
                    style={{cursor: 'not-allowed'}}>
                    승인
                  </Button>
                </>
              )}
            </ButtonWrapper>
          </>
        )}
      </ProcessConatiner>
    </MainContainer>
  )
}

export default Process

const StyledWrapper = styled.div`
  padding-left: 5%;
  display: flex;
  width: 100%;
  margin-top: 24px;
  border-bottom: 1px solid;
`
const MainContainer = styled.div`
  padding-top: 35px;
`

const ProcessConatiner = styled.div`
  margin: 0 auto;
  width: 100%;
`

const ProjectInfoWrapper = styled.div`
  display: flex;
  padding-left: 5%;
  gap: 20px;
  align-items: center;
`

const ProjectVersion = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85px;
  height: 30px;
  border-radius: 6px;
  margin: 10px 0;
  font-size: 0.9rem;
  font-weight: ${theme.fontWeight.bold};
  color: ${theme.color.black};
  background-color: ${theme.color.green300};
`

const ProcessWrapper = styled.div`
  padding-left: 5%;
  display: flex;
  width: 100%;
  margin-top: 24px;
  flex-direction: column;
`

const ProcessAlign = styled.div`
  width: 100%;
`

const Title = styled.p`
  font-size: 1.875rem;
  font-weight: ${theme.fontWeight.bold};
`

const LiskWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0;
`

const LiskTitle = styled.p`
  gap: 10px;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: ${theme.fontWeight.bold};
  padding-top: 30px;
`

const LiskSubTitle = styled.p`
  font-size: 1rem;
  color: ${theme.color.gray700};
`

const FileContainer = styled.div`
  display: flex;
  align-items: center;

  font-size: 1rem;
  color: ${theme.color.black};
  cursor: pointer;
  text-decoration: underline;
  width: fit-content;
  padding: 0 20px;
  height: 42px;
  background-color: ${theme.color.gray50};
  border-radius: 6px;
  margin-top: 10px;
`

const FileWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: ${theme.color.black};
  text-decoration: underline;
  width: 80%;
  padding: 0 20px;
  height: 42px;
  background-color: ${theme.color.gray50};
  border-radius: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const FileItem = styled.div`
  cursor: pointer;
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const LinkItem = styled(Link)`
  padding-left: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const ProcessFileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const CommentListWrapper = styled.div`
  width: 100%;
  margin-top: 15px;
`

const ProcessDescription = styled.textarea`
  width: 90%;
  height: 200px;
  border: 1px solid ${theme.color.gray50};
  border-radius: 6px;
  margin-top: 15px;
  padding: 20px;
  font-size: 1rem;

  &::placeholder {
    color: ${theme.color.gray700};
    font-size: 1rem;
  }
`

const ProcessDescriptionDiv = styled.div`
  width: 90%;
  height: 200px;
  border: 1px solid ${theme.color.gray50};
  border-radius: 6px;
  margin-top: 15px;
  padding: 20px;
  font-size: 1rem;
`

const StyledFileInput = styled.div`
  margin-top: 15px;
`

const StyledLinkInput = styled.input`
  width: 500px;
  border: 1px solid ${theme.color.gray50};
  border-radius: 6px;
  margin-top: 15px;
  padding: 10px;
  font-size: 1rem;
  height: 42px;
  &::placeholder {
    color: ${theme.color.gray700};
    font-size: 1rem;
  }
`

const StyledRequired = styled.span`
  color: ${theme.color.red};
`
const ButtonWrapper = styled.div<{hasError: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: ${({hasError}) => (hasError ? '30px' : '30px')};
  padding-bottom: 50px;
  flex-direction: column;
`

const ErrorMessage = styled.p`
  color: ${theme.color.red};
  font-size: 1rem;
  text-align: center;
  margin-bottom: 10px;
`
const ErrorText = styled.p`
  color: ${theme.color.red};
  font-size: 1rem;
  margin-top: 10px;
`
