import {atom} from 'recoil'
import {recoilPersist} from 'recoil-persist'

const {persistAtom} = recoilPersist({
  key: 'recoilVersionPersist',
  storage: localStorage,
})

export const prjIdAtom = atom({
  key: `prjIdAtom`,
  default: 0,
})

export const versionAtom = atom({
  key: `versionAtom`,
  default: '',
  effects_UNSTABLE: [persistAtom],
})

export const secUserAtom = atom({
  key: `secUserAtom`,
  default: [],
  effects_UNSTABLE: [persistAtom],
})

export const devUserAtom = atom({
  key: `devUserAtom`,
  default: [],
  effects_UNSTABLE: [persistAtom],
})

export const requiredAtom = atom({
  key: `requiredAtom`,
  default: true,
})

export const delYNAtom = atom({
  key: `delYNAtom`,
  default: 'Y',
})
