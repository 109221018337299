import {AxiosError} from 'axios'
import {instanceFiles} from '..'

// 프로젝트 생성
export async function handleCreatePrj(data: FormData) {
  try {
    const response = await instanceFiles.post('/prj/ctPrj', data)
    return response.data
  } catch (e: unknown) {
    e instanceof AxiosError
      ? alert(e.response?.data.message || e.message)
      : console.log('알 수 없는 오류가 발생하였습니다:', e)
  }
}

// 프로젝트 버전 생성
export async function handleCreatePrjVersion(data: FormData) {
  try {
    const response = await instanceFiles.post('/prj/addPrjVer', data)
    return response.data
  } catch (e: unknown) {
    e instanceof AxiosError
      ? alert(e.response?.data.message || e.message)
      : console.log('알 수 없는 오류가 발생하였습니다:', e)
  }
}

// 특정 프로젝트 수정
export async function handleModifyPrj(data: FormData) {
  try {
    const response = await instanceFiles.put('/prj/updtPrj', data)
    return response.data
  } catch (e: unknown) {
    e instanceof AxiosError
      ? alert(e.response?.data.message || e.message)
      : console.log('알 수 없는 오류가 발생하였습니다:', e)
  }
}
